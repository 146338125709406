import React from 'react'
import { Nav } from 'react-bootstrap'

export interface IRwNav {
  className?: string
  fill?: boolean
  flush?: boolean
  [x: string]: any
}

const RwNav: React.FC<IRwNav> = ({
  children,
  className = '',
  fill = false,
  flush = false,
  ...rest
}) => {
  const alignClass = fill ? 'ms-auto' : ''
  return (
    <Nav
      className={`${flush ? '' : 'py-1'} me-3 ${alignClass} ${className}`}
      {...rest}
    >
      {children}
    </Nav>
  )
}

export default RwNav
