import React, { forwardRef } from 'react'

export interface IRwTableRow {
  className?: string
  highlightedClassName?: string
  isHighlighted?: boolean
  align?: 'top' | 'middle' | 'bottom'
  cursor?: 'pointer' | 'move' | 'default'
  [x: string]: any
}

const RwTableRow: React.FC<IRwTableRow> = forwardRef((props, ref: any) => {
  const {
    children,
    className = '',
    highlightedClassName = '',
    isHighlighted = false,
    align = 'middle',
    cursor = 'default',
    ...rest
  } = props

  const alignClass = `align-${align}`
  return (
    <tr
      ref={ref}
      className={`${
        isHighlighted === false ? className : highlightedClassName
      } ${alignClass}`}
      style={{ cursor }}
      {...rest}
    >
      {children}
    </tr>
  )
})

export default RwTableRow
