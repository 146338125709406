import React from 'react'
import { Dropdown, ListGroup, OverlayTrigger } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'

interface IRowContextMenu {
  [x: string]: any
  rootClose?: boolean
  isModal?: boolean
  disabled?: boolean
  hideOnSelect?: boolean
}

const RowContextMenu: React.FC<IRowContextMenu> = ({
  children,
  rootClose = false,
  isModal = false,
  disabled = false,
  hideOnSelect = false,
}) => {
  const [show, setShow] = React.useState(false)
  const handleHide = () => {
    if (hideOnSelect) setShow(false)
  }

  return (
    <>
      <Dropdown align="end">
        <Dropdown.Toggle
          className="dropdown-no-caret"
          variant="link"
          id="row-context-menu"
        >
          <BsThreeDotsVertical className="fs-4" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default RowContextMenu
