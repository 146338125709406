export const GET_MAIN_NAV_MENU_ITEMS = 'GET_MAIN_NAV_MENU_ITEMS'
export const GET_TOP_NAV_MENU_ITEMS = 'GET_TOP_NAV_MENU_ITEMS'
export const GET_STORE_SETTINGS = 'GET_STORE_SETTINGS'
export const GET_APP_INFO = 'GET_APP_INFO'
export const SET_APP_VERSION_CURRENT = 'SET_APP_VERSION_CURRENT'
export const GET_APP_SETTINGS = 'GET_APP_SETTINGS'
export const TOGGLE_MENU_ITEM_OPEN = 'TOGGLE_MENU_ITEM_OPEN'
export const SET_SHOW_TOP_NAV = 'SET_SHOW_TOP_NAV'
export const SET_SHOW_SIDE_NAV = 'SET_SHOW_SIDE_NAV'
export const SET_USER_SHOW_TOP_NAV = 'SET_USER_SHOW_TOP_NAV'
export const SET_USER_SHOW_SIDE_NAV = 'SET_USER_SHOW_SIDE_NAV'
export const SET_USER_SHOW_CRUMBS = 'SET_USER_SHOW_CRUMBS'
export const GET_AUDIT_LIST = 'GET_AUDIT_LIST'
export const GET_WAREHOUSE_PRINTERS = 'GET_WAREHOUSE_PRINTERS'
export const SET_MARKETING_PAGE_FILTER = 'SET_MARKETING_PAGE_FILTER'
